const INFO = {
	main: {
		title: "Gleyce",
		name: "Gleyce Noronha",
		email: "gleyce.noronha.s@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://www.linkedin.com/in/gleyce-noronha/",
		instagram: "https://www.instagram.com/gleyce_noronha/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Logistic Manager.",
		description:
			"Experienced professional in Materials, Supplies, and Logistics, with roles such as Logistics Manager and Senior International Buyer. Skilled in material management, supply chain, logistics, customs operations, KPI management, and regulatory compliance (IATA, OEA, Anvisa, ISO). Proficient in creating reports for strategic decision-making.",
	},

	about: {
		title: "I’m Gleyciane Noronha. A Logistic Manager.",
		description:
			"Professional specialized in Materials, Supplies, and Logistics, with a wide range of experiences that include positions such as Logistics Manager, Foreign Trade Coordinator, Materials Supervisor, Senior International Buyer, and International Buyer. I have extensive experience in managing the planning, control, receipt, and shipment of materials, both in the domestic and international markets.Throughout my career, I have acquired exceptional skills in managing operational supply chains, administering distribution centers, and controlling inventories and stocks, as well as in production, logistics processes, and customs operations. I have solid experience in KPI management, negotiations, supplier evaluation, audits, and regulatory compliance, including standards such as IATA, OEA, Anvisa, and ISO. Additionally, I possess expertise in preparing complex reports, offering detailed and strategic insights for decision-making.",
	},

	skils: [
		{
			title: "Skil - 1",
			description: "- Test "
		}, 
		{
			title: "Skil 2",
			description: "- Test "
		}
	],

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
