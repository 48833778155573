import React from "react";


function article_1() {
	return {
		date: "10 May 2024",
		link: "https://www.linkedin.com/pulse/log%25C3%25ADstica-na-nova-era-da-tecnologia-gleyce-noronha-hse6f/?trackingId=omjOH0GoSN6EBMVQGAHBJw%3D%3D",
		title: "The logistics in the new era of technology",
		description:
			"Technology in logistics ensures that distribution centers have automated, robust, and organized processes. Thus, inventory control, customer data, tracking, financials, and others can be monitored with the help of one or more logistic software.",
		style: ``,
		keywords: [
			"Supply Chain",
			"Operations"
		],
		body: (
			<React.Fragment>
				<h1>Content of article 1</h1>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "9 May 2024",
		link: "https://www.linkedin.com/pulse/quais-os-maiores-desafios-da-log%25C3%25ADstica-na-amaz%25C3%25B4nia-gleyce-noronha-4wtgc/?trackingId=pSU1Xkp2T2CzeC5NOvqFBA%3D%3D",
		title: "What are the main challenges of logistics in the Amazon?",
		description:
			"One of the primary challenges faced by the sector lies in integrating the Manaus Free Trade Zone, the largest industrial area in the region, with the rest of the country. The capital of Amazonas has only one land connection with Roraima, while the waterway infrastructure is inadequate, leaving air transport as the only viable option for low-volume, high-value-added products.",
		style: ``,
		keywords: [
			"Supply Chain",
			"Operations"
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "3 May 2024",
		title: "The world runs on supply chains",
		link: "https://www.linkedin.com/pulse/o-mundo-funciona-com-cadeias-de-abastecimento-gleyce-noronha-fxdlf/?trackingId=UkMswovDTq6BTAAIDA3CtA%3D%3D",
		description:
			"The gears of the world turn around supply chains. Indeed, studies show that approximately 67% of companies recognize effective supply chain and logistics management as a crucial competitive advantage to expand their operations. Efficiency in this area is the engine of the modern economy, and any disruption, no matter how minor, can trigger a domino effect that affects every aspect of our daily lives...",
		keywords: [
			"Operations",
			"Supply",
			"Chain",
			"Buy",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
				</div>
			</React.Fragment>
		),
	};
}

function article_4() {
	return {
		date: "3 April 2024",
		link: "https://www.linkedin.com/pulse/grandes-desafios-p%25C3%25B3s-pandemia-2021-22-23-gleyce-noronha-xrg0f/?trackingId=pSU1Xkp2T2CzeC5NOvqFBA%3D%3D",
		title: "Great post-pandemic challenges 2021-22-23",
		description:
			"The most common challenges in 2021 and 2022 were port congestions, production delays, and extreme weather events (including hurricanes, tornadoes, and wildfires). Additionally, there were also several outliers to face – such as new COVID variants and factory closures.",
		style: ``,
		keywords: [
			"Operations",
			"Supply",
			"Chain",
			"Buy",
		],
		body: (
			<React.Fragment>
				<h1>Content of article 4</h1>
			</React.Fragment>
		),
	};
}

function article_5() {
	return {
		date: "5 Jun 2024",
		link: "https://www.linkedin.com/pulse/supply-chain-da-pr%25C3%25B3xima-gera%25C3%25A7%25C3%25A3o-transformando-o-modelo-gleyce-noronha-xsgic/?trackingId=pSU1Xkp2T2CzeC5NOvqFBA%3D%3D",
		title: "Next-generation supply chain - transforming the operational model of your supply chain for a digital world",
		description:
			"Whether opting to be faster, more innovative, or closer to customers, it's increasingly challenging for manufacturers and retailers to launch new products and services that flexibly respond to shifting demand while still maintaining (or even improving) profitability. It's no surprise that leading players are focused on improving their supply chain - especially when it comes to enhancing service levels, reducing costs, or optimizing inventory levels.",
		style: ``,
		keywords: [
			"Operations",
			"Supply",
			"Chain",
			"Buy",
		],
		body: (
			<React.Fragment>
				<h1>Content of article 5</h1>
			</React.Fragment>
		),
	};
}

const myArticles = [article_5, article_1, article_2, article_3, article_4];

export default myArticles;
